import axios from "./Axios";
import $ from 'jquery'
function getData(data){
    return axios.post('/index.php?store_id=1&store_type=6',data)
}

function _post1(data) {
	return new Promise((resolve, reject) => {
		$.ajax({
			url: 'https://admin.iseso.net//index.php?store_id=1&store_type=6',
			type: "POST",
			data: data,
			async: false,
			success: function(res) {
				if (typeof res == 'string') {
					res = JSON.parse(res)
				}

				if (res.code == 200) {
					resolve(res)
				} else {
					if (res.message == '请登录！' || res.message == '请重新登录！') {
						setTimeout(() => {
							location.href = "pages/login/login.html?isReturn=1"
						}, 1000)
					}

					reject(res)
				}
			},
			error: function(data) {
				data.message = '请求失败！'
				reject(data)
			}
		});

	})
}

function getCart() {
	var access_id;
	if(localStorage.getItem('pc_user')){
		access_id = JSON.parse(localStorage.getItem("pc_user")).access_id
	}
	else{
		access_id = ''
	}
	const data = {
            module:'app_pc',
            action:'index',
            m:'index',
            access_id:access_id
        }
    return axios.post('/index.php?store_id=1&store_type=6', data);
}
export {_post1,getData,getCart}

