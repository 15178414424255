<template>
    <div class="s-head">
        <div class="s-con">
            <img src="../assets/image/aa_pic_logo@2x.png" class="left-img"/>
            <div class="s-right-con">
                <div class="right-input">
                    <input class="input" placeholder="荧光颜料" v-model="inputValue">
                    <div class="space"></div>
                    <img src="../assets/image/aa_icon_search@2x.png" class="img-search"/>
                    <div class="search" @click="tosearch(inputValue)">搜索</div>
                </div>
                <div class="right-bottom">
                    <div class="hot-search">热门搜索:</div>
                    <div class="keywords" v-for="(item,index) in state" :key="index">{{item.keyword}}</div> 
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {getData} from '@/utils/req'
import {onMounted,ref,reactive} from 'vue'
import {useRouter} from 'vue-router'
export default {
    setup() {
        const router = useRouter()
        let state = ref([]);
        let pc_user = reactive({
            "access_id":"",
        })
        let inputValue = ref('')
        onMounted(()=>{
            pc_user.access_id = JSON.parse(localStorage.getItem("pc_user")).access_id
             const data={
                module:'app_pc',
                action:'index',
                m:'click_search',
                access_id:pc_user.access_id
            }
            getData(data).then(res=>{
                if(res.code ===200){
                    state.value = res.data
                }
            })
        })
        const tosearch = (value)=>{
            router.push({path:'/homelist',query:{sValue:value}})
        }
        return{
            inputValue,
            tosearch
        }
    },
}
</script>
<style scoped>
    .s-head{
        width:100%;
    }
    .s-con{
        width: 1200px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .s-con .left-img{
        width: 300px;
    }
    .s-con .s-right-con{
        width: 605px;
        display: flex;
        flex-direction: column;
        padding-top: 25px;
    }
    .right-input{
        position: relative;
        height: 52px;
    }
    .right-input .img-search{
        width: 20px;
        height: 20px;
        position: absolute;
        top: 15px;
        left: 20px;
    }
    .hot-search{
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        margin-top: 5px;
    }
    .right-input .input{
        position: absolute;
        top: 0px;
        width:605px;
        height: 52px;
        border:1px solid #DA251C;
        padding-left: 75px;
        cursor: text;
        font-size: 12px;
    }
    .right-input .space{
        position: absolute;
        top: 21px;
        left: 60px;
        width: 1px;
        height: 9px;
        background-color: #e0e0e0;

    }
    .right-input .search{
        position: absolute;
        top: 0px;
        right: 0px;
        width: 152px;
        height: 52px;
        background-color: #DA251C;
        border:1px solid #DA251C;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 50px;
        text-align: center;
    }
    .right-bottom{
        display: flex;
        align-items: center;
    }
    .right-bottom .keywords{
        display: flex;
        align-items: center;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 17px;
        display: flex;
        align-items: center;
        margin-top: 6px;
        margin-left: 10px;
    }
</style>