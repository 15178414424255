<template>
    <div class="bottom">
        <div class="bottom-con">
            <div class="item-con" @click="tohome">
                <img src="@/assets/image/aa_pic_logo_2@_x2.png" class="img1"/>
            </div>
            <div class="item-con">
                <div class="menu-con">
                    <div class="menu-item">
                        <div class="item" @click="toRoute(1,'关于我们')">关于我们</div>
                        <div class="item" @click="toRoute(2,'帮助中心')">帮助中心</div>
                        <div class="item" @click="toRoute(3,'新手指南')">新手指南</div>
                        <div class="item" @click="toRoute(4,'售后服务')">售后服务</div>
                        <div class="item" @click="toRoute(5,'支付方式')">支付方式</div>
                    </div>
                </div>
            </div>
            <div class="item-con">
                <div class="serve">
                    <div class="item">
                        <div class="c-title">7X9小时在线客服</div>
                        <div class="e-title">Online customer service</div>
                    </div>
                    <div class="item">
                        <div class="c-title">闪电发货</div>
                        <div class="e-title">Online customer service</div>
                    </div>
                </div>
            </div>
            <div class="item-con">
                <div class="smallp">
                    <img src="@/assets/image/1.jpeg" class="img2"/>
                    <p>扫码体验爱色素商城小程序</p>
                </div>
            </div>
            
        </div>
        <div class="foot" style="cursor:pointer;" @click="tourl">
             Copyright © 2021 爱色素版权所有[官方网站] 粤ICP备18131597号-3
        </div>
    </div>
</template>
<script>
import {useRouter} from 'vue-router'
export default {
    setup(){
        const router = useRouter()
        const tohome =()=>{
            location.href = '/'
        }
        const tourl =()=>{
            window.open('https://beian.miit.gov.cn/')
        }
        const toRoute = (index,info) =>{
            if(index ==1){
                router.push({
                    path:'/serve/aboutus',
                    query:{
                        info:info
                    }
                })
            }
            else if(index ==2){
                router.push({
                    path:'/serve/after_sale',
                    query:{
                        info:info
                    }
                })
            }
            else if(index ==3){
                router.push({
                    path:'/serve/shopping',
                    query:{
                        info:info
                    }
                })
            }
            else if(index ==4){
                router.push({
                    path:'/serve/thpolicy',
                    query:{
                        info:info
                    }
                })
            }
            else if(index ==5){
                router.push({
                    path:'/serve/paytype',
                    query:{
                        info:info
                    }
                })
            }
            
        }
        return{
            tohome,
            toRoute,
            tourl
        }
    }
}
</script>
<style scoped>
    .bottom{
        width: 100%;
        position: absolute;
        bottom: -245px;
        left: 0px;
    }
    .bottom-con{
        width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: space-evenly;
        position: relative;
    }
    .bottom-con .item-con{
        position: relative;
        margin-right: 30px;
    }
    .bottom-con .item-con:before{
        content: '';
        display: block;
        width: 1px;
        height: 150px;
        position: absolute;
        top: 35px;
        left: -35px;
        background-color: #e0e0ee;
    }
    .bottom-con .item-con:last-child:after{
        content: '';
        display: block;
        width: 1px;
        height: 150px;
        position: absolute;
        top: 35px;
        left: 185px;
        background-color: #e0e0ee;
    }
    .bottom-con .img1{
        width: 248px;
        height: 202px;
        cursor: pointer;
    }
    .menu-con{
        margin-top: 50px;
    }
    .menu-item{
        display: flex;
        flex-wrap: wrap;
        width: 260px;
    }
    .menu-item .item{
        width: 130px;
        font-size: 14px;
        color: #020202;
        font-weight: 400;
        width: 50%;
        margin-top: 20px;
        cursor: pointer;
        text-align: center;
    }
    .serve{
        margin-top: 50px;
    }
    .serve .item{
        margin-top: 30px;
    }
    .serve .item .c-title{
        font-size: 16px;
        font-weight: 600;
        color: #777;
    }
    
    .serve .item .e-title{
        font-size: 12px;
        color: #ccc;
    }
    .smallp{
        display: flex;
        flex-direction: column;
        margin-top: 50px;
    }
    .smallp .img2{
        width: 100px;
        display: block;
        height: 100px;
        margin: 0 auto;
    }
    .smallp p{
        text-align: center;
        font-size: 12px;
        color: #ccc;
        margin-top: 20px;
    }
    .foot{
        font-size: 12px;
        color: #ccc;
        width: 100%;
        border-top: 1px solid #ccc;
        padding: 15px 0;
        text-align: center;
    }
</style>