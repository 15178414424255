<template>
    <div class="head">
        <div class="head-container">
        <div class="left-con">
            <div @click="homepage">爱色素化工商城欢迎您！</div>
            <div @click="homepage">商场首页</div>
        </div>
        <ul class="right-con">
            <li>
                <template v-if="!user_name">
                    <div class="login-register">
                        <div @click="toLogin">登录</div>
                        <div @click="toRegister">注册</div>
                    </div>
                    
                </template>
                <template v-else>
                    <p>{{user_name}},<span @click="unLogin">退出账号</span></p>
                </template>
            </li>
            <li style="position:relative">
                <div class="mycount" @mouseenter="changeMenuState" @mouseleave="changeMenuState1">
                    <div style="display:flex;align-items:center;">
                        <span>我的账户</span>
                        <el-icon size="16"><arrow-down /></el-icon>
                    </div>
                </div>
                <div class="hide-select" v-show="menushow" @mouseenter="changeMenuState2" @mouseleave="changeMenuState3">
                    <p @click="toCollect">我的收藏</p>
                    <p @click="toCart">我的购物车({{cart_num}})</p>
                    <p @click="toCoupon">优惠券({{coupon_num}})</p>
                </div>
            </li>
            <li class="phone">
                服务热线:076922000017
            </li>
            <li class="phone">
                联系手机:13798710346
            </li>
        </ul>
    </div>
    </div>
    
</template>
<script>
import {reactive,onMounted,ref,computed} from 'vue'
import {useRouter} from 'vue-router'
import {useStore} from 'vuex'
import { getData } from '@/utils/req'
import { ElMessage } from 'element-plus'
import { ArrowDown } from '@element-plus/icons'
export default({
    components:{
        ArrowDown
    },
    setup() {
        const router = useRouter()
        const store = useStore()
        let pc_user = reactive({
            "access_id":"",
        })
        let menushow = ref(false)
        onMounted(()=>{
            pc_user.access_id = JSON.parse(localStorage.getItem("pc_user")).access_id
            store.dispatch('updateCart')
        })

        const changeMenuState = ()=>{
            menushow.value = true
        }
        const changeMenuState1 = ()=>{
            menushow.value = false
        }
        const changeMenuState2 = ()=>{
            menushow.value = true
        }
        const changeMenuState3 = ()=>{
            menushow.value = false
        }
        const toLogin=()=>{
            router.push({path: '/login', query: {selected: 1}})
        }
        const toRegister =()=>{
            router.push({path: '/login', query: {selected: 2}})
        }
        const user_name =computed(()=>{
            return store.state.user_name
        })
        const coupon_num = computed(()=>{
            return store.state.coupon_num
        })
        const cart_num = computed(()=>{
            return store.state.cart_num
        })
        const unLogin =()=>{
            const data = {
                module: 'app_pc',
                action: 'login',
                m: 'quit',
                access_id: store.state.access_id,
            }
            getData(data).then(res=>{
                console.log(res)
                ElMessage({
                    showClose: true,
                    message: '退出成功',
                    type: 'success',
                    duration:1500
                })
            })
            localStorage.removeItem("pc_user")
            store.dispatch('updateCart')
            router.push({
                path:'/'
            })
            location.reload()
        }
        const homepage =()=>{
            location.href = '/'
        }
        const toCollect =()=>{
            router.push('/profile/collect')
        }
        const toCart =()=>{
            router.push({
                path:'/shopcart'
            })
        }
        const toCoupon =()=>{
            router.push('/profile/coupon')
        }
        return{
            user_name,
            coupon_num,
            cart_num,
            menushow,
            changeMenuState,
            changeMenuState1,
            changeMenuState2,
            changeMenuState3,
            toLogin,
            toRegister,
            unLogin,
            homepage,
            toCollect,
            toCart,
            toCoupon
        }
    },
})
</script>
<style scoped>
    .head{
        width: 100%;
        height: 36px;
        border-bottom: 1px solid #ccc;
    }
    .head-container{
        display: flex;
        width: 1200px;
        height: 36px;
        line-height: 36px;
        margin: 0 auto;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        color: #999;
        font-weight: 500;
    }
    .left-con{
        display: flex;
        align-items: center;
    }
    .left-con div{
        cursor: pointer;
        position: relative;
    }
    .left-con div:first-child{
        margin-right: 20px;
    }
    .left-con div:first-child:after{
        content:'';
        position:absolute;
        top:15px;
        left:160px;
        display: block;
        width: 1px;
        height: 9px;
        background-color: #e0e0e0;
    }
    .right-con{
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        color:#999;
        font-weight: 500;
    }
    .right-con .phone{
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        color: #333;
        font-weight: 500;
    }
    .right-con li{
        cursor: pointer;
    }
    .right-con li:not(:last-child){
        position: relative;
        margin-right: 20px;
    }
    .right-con li:not(:last-child):after{
        content:'';
        position:absolute;
        top:13px;
        right:-10px;
        display: block;
        width: 1px;
        height: 9px;
        background-color: #e0e0e0;
    }
    .right-con .login-register{
        display: flex;
        position: relative;
    }
    .right-con .login-register div:last-child{
        margin-left: 25px;
    }
    .right-con .login-register div:last-child:after{
        position: absolute;
        content: '';
        width: 1px;
        height: 9px;
        top: 13px;
        right:37px;
        background-color: #e0e0e0;
        display: block;
    }
    .hide-select{
        position: absolute;
        z-index: 1000;
        left:-10px;
        display: flex;
        flex-direction: column;
        width: 95px;
        height: 110px;
        font-size: 10px;
        font-family: PingFangSC-Medium, PingFang SC;
        color: #999;
        text-align: center;
        box-shadow:0px 0px 8px 0px rgba(0, 0, 0, 0.16);
        background: #fff;
    }
    .hide-select:before{
        content: '';
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-bottom-color: #e0e0e0;
        position: absolute;
        left: 33px;
        bottom: 100%;
        margin-top: 20px;
    }
    .hide-select p:not(:last-child){
        border-bottom: 1px solid #ddd;
    }
    .hide-select p:hover{
        color: red;
        cursor: pointer;
    }
</style>
