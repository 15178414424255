<template>
    <Nav v-if="$route.meta.nav"/>
    <Navi v-if="$route.meta.navi"/>
    <router-view></router-view>
    <div style="position:relative;">
      <Bottom></Bottom>
    </div>
    <navRight v-if="$route.meta.nav"/>
</template>
<script>
import Nav from '@/components/Nav.vue'
import Navi from '@/components/Navi.vue'
import Bottom from '@/components/Bottom.vue'
import navRight from '@/components/NavRight.vue'
import {onMounted} from 'vue'

export default {
  setup() {
    onMounted(()=>{
      if(!localStorage.getItem("pc_user"))
      {
        localStorage.setItem("pc_user","{}")
      }
    })
    return{
    }
  },
  components:{
    Nav,
    Navi,
    Bottom,
    navRight
  }
}
</script>
<style lang="scss">
@import "assets/css/reset.css"
</style>
