import { getCart } from "../utils/req";

export default {
    async updateCart(ctx) {
      const res = await getCart()
      const cart_num =res.data.cart_num
      const user_name =res.data.user_name
      const coupon_num = res.data.coupon_num
      const classList = res.data.class_list
      localStorage.setItem("class_list",JSON.stringify(classList))
      ctx.commit('addCart', {
        cart_num:cart_num ||0,
        username:user_name ||'',
        coupon_num:coupon_num||0
      })
    }
  }