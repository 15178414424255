<template>
    <div id="navRight1" class="r-nav" style="height: 240px;">
        <ul>
            <li class="toUp" @click="toUp">
                <div class="toUpImg"></div>
                <span>顶部</span>
            </li>
            <li class="qr-kefu" @click="toCart">
                <el-badge :value="cart_num" class="item">
                    <div class="qr-kefuImg"></div>
                </el-badge>
                <span>购物车</span>
            </li>
            <li class="qr-kefu" @click="tocontract">
                <div class="qr-kefuImg1"></div>
                <span>客服</span>
            </li>
        </ul>
    </div>
</template>
<script>
import { computed } from 'vue'
import {useRouter} from 'vue-router'
import {useStore} from 'vuex'
export default {
    setup(){
        const router =useRouter()
        const store = useStore()
        const toUp = () =>{
            (function smoothscroll(){
                var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                if (currentScroll > 0) {
                        window.requestAnimationFrame(smoothscroll);
                        window.scrollTo (0,currentScroll - (currentScroll/2));
                }
            })();
        }
        const cart_num = computed(()=>{
            return store.state.cart_num
        })
        const toCart =()=>{
            router.push({
                path:'/shopcart'
            })
        }
        const tocontract = () =>{
            window.open('https://chatlink123.meiqia.cn/widget/standalone.html?eid=91e310ce28069b6d086aebc6ce04b69f')
        }
        return{
            toUp,
            toCart,
            tocontract,
            cart_num
        }
    }
}
</script>
<style scoped>
.r-nav{
    position: fixed;
    bottom: 180px;
    right: 30px;
    width: 75px;
    height: 173px;
    margin: auto;
    box-shadow:0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    z-index: 222;
	background-color: #FFFFFF;
}
.r-nav ul li{
    width: 100%;
    height: 65px;
    margin: auto;
    text-align: center;
	cursor: pointer;
	margin-top: 10px;
}
.r-nav ul li .toUpImg{
    display: block;
    width: 23px;
    height: 23px;
    margin: 15px auto;
    background-image: url('../assets/image/dingbu.png');
    background-size: cover;
    background-repeat: no-repeat;
}
.r-nav ul li .toUpImg:hover{
    background-image: url('../assets/image/dingbu_h.png');
}
.r-nav ul li .qr-kefuImg{
    display: block;
    width: 23px;
    height: 23px;
    margin: 15px auto;
    background-image: url('../assets/image/cart.png');
    background-size: cover;
    background-repeat: no-repeat;
}

.r-nav ul li .qr-kefuImg:hover{
    background-image: url('../assets/image/cart.jpg');
}
.r-nav ul li .qr-kefuImg1{
    display: block;
    width: 23px;
    height: 23px;
    margin: 15px auto;
    background-image: url('../assets/image/kefu.png');
    background-size: cover;
    background-repeat: no-repeat;
}

.r-nav ul li .qr-kefuImg1:hover{
    background-image: url('../assets/image/kefu_h.png');
}
.r-nav ul li span{
    display: block;
    margin: -10px auto;
    font-size: 14px;
    color: #666666;
}
</style>