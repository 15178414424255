import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: ()=> import(/* webpackChunkName "home" */ '@/views/home/Home.vue'),
    meta:{
      nav:true,
      navi:true
    }
  },
  {
    path:'/login',
    name:"Login",
    component:()=> import(/*webpackChunkName */ '@/views/login/Login.vue'),
    children:[
      {
        path:'',
        name:"Register",
        component:()=> import(/*webpackChunkName */ '@/views/login/child/Register.vue')
      },
      {
        path:'findcode',
        name:"Findcode",
        component:()=>import(/*webpackChunkName*/ '@/views/login/child/Findcode.vue')
      }
    ],
    meta:{
      nav:false,
      navi:false
    }
  },
  {
    path:'/homelist',
    name:"Homelist",
    component:() => import(/*webpackChunkName*/ '@/views/homelist/Homelist.vue'),
    meta:{
      nav:true,
      navi:true
    }
  },
  {
    path:'/homedetail',
    name:"Homedetail",
    component:() => import(/*webpackChunkName*/ '@/views/homedetail/Homedetail.vue'),
    meta:{
      nav:true,
      navi:true
    }
  },
  {
    path:'/profile',
    name:"Profile",
    component:() => import(/*webpackChunkName*/ '@/views/profile/Profile.vue'),
    beforeEnter: (to, from,next) => {
      let pc_user = JSON.parse(localStorage.getItem('pc_user'))
      if(pc_user.user_name){
        next()
      }
      else{
        next({path: '/login', query: {selected: 1}})
      }
    },
    children:[
      {
        path:'order',
        name:'Order',
        component:()=> import(/* webpackChunkName */ '@/views/profile/child/Myorder.vue')
      },
      {
        path:'aftersale',
        name:'Aftersale',
        component:()=> import(/*webpackChunkName */ '@/views/profile/child/Aftersale.vue')
      },
      {
        path:'coupon',
        name:'Coupon',
        component:()=> import(/*webpackChunkName*/ '@/views/profile/child/Coupon.vue')
      },
      {
        path:'message',
        name:'Message',
        component: ()=> import(/*webpackChunkName */ '@/views/profile/child/Message.vue')
      },
      {
        path:'collect',
        name:'Collect',
        component:()=> import(/*webpackChunkName */ '@/views/profile/child/Collect.vue')
      },
      {
        path:'address',
        name:'Address',
        component:()=> import(/*webpackChunkName */ '@/views/profile/child/Address.vue')
      },
      {
        path:'info',
        name:'Info',
        component:()=>import(/*webpackChunkName*/ '@/views/profile/child/Info.vue')
      },
      {
        path:'security',
        name:'Security',
        component:()=> import(/*webpackChunkName*/ '@/views/profile/child/Security.vue')
      }
    ],
    meta:{
      nav:true,
      navi:false
    }
  },
  {
    path:'/shopcart',
    name:"Shopcart",
    beforeEnter: (to, from,next) => {
      let pc_user = JSON.parse(localStorage.getItem('pc_user'))
      if(pc_user.user_name){
        next()
      }
      else{
        next({path: '/login', query: {selected: 1}})
      }
    },
    component:() => import(/*webpackChunkName*/ '@/views/shopcart/Shopcart.vue'),
    meta:{
      nav:true,
      navi:true
    }
  },

  {
    path:'/shop',
    name:"Shop",
    beforeEnter: (to, from,next) => {
      let pc_user = JSON.parse(localStorage.getItem('pc_user'))
      if(pc_user.user_name){
        next()
      }
      else{
        next({path: '/login', query: {selected: 1}})
      }
    },
    component:() => import(/*webpackChunkName*/ '@/views/shop/shop.vue'),
    meta:{
      nav:true,
      navi:true
    }
  },
  {
    path:'/serve',
    name:'Serve',
    component:() => import(/*WebpackChunkName */ '@/views/bottom/index.vue'),
    meta:{
      nav:true,
      navi:false
    },
    children:[
      {
        path:'after_sale',
        name:'AfterSale',
        component:()=>import(/*webpackChunkName*/ '@/views/bottom/child/after_sale.vue')
      },
      {
        path:'pay_question',
        name:'payQuestion',
        component:() =>import(/*webpackChunkName*/ '@/views/bottom/child/payQuestion.vue')
      },
      {
        path:'shopping',
        name:'Shopping',
        component :() =>import(/*webpackChunkName */ '@/views/bottom/child/shopping.vue')
      },
      {
        path:'paytype',
        name:'Paytype',
        component:() => import(/*webpackChunkName */ '@/views/bottom/child/payType.vue')
      },
      {
        path:'thpolicy',
        name:'Thpolicy',
        component:() => import(/*webpackChunkName */ '@/views/bottom/child/thPolicy.vue')
      },
      {
        path:'cancel',
        name:'Cancel',
        component :() => import(/*webpackChunkName */ '@/views/bottom/child/cancle.vue')
      },
      {
        path:'thprocess',
        name:'thProcess',
        component:() => import(/*webpackChunkName */ '@/views/bottom/child/thProcess.vue')
      },
      {
        path:'tkinfo',
        name:'tkinfo',
        component:() => import(/*webpackChunkName */ '@/views/bottom/child/tkinfo.vue')
      },
      {
        path:'aboutus',
        name:'Aboutus',
        component:() => import(/*webpackChunkName */ '@/views/bottom/child/aboutus.vue')
      },
      {
        path:'contractus',
        name:'Contract',
        component:() => import(/*webpackChunkName */ '@/views/bottom/child/contractus.vue')
      }
    ]
  },
  {
    path:'/paydetail',
    name:'payDetail',
    beforeEnter: (to, from,next) => {
      let pc_user = JSON.parse(localStorage.getItem('pc_user'))
      if(pc_user.user_name){
        next()
      }
      else{
        next({path: '/login', query: {selected: 1}})
      }
    },
    component:() => import(/*webpackChunkName */ '@/views/paydetail/paydetail.vue'),
    meta:{
      nav:true,
      navi:true
    }
  },
  {
    path:'/pay',
    name:'pay',
    beforeEnter: (to, from,next) => {
      let pc_user = JSON.parse(localStorage.getItem('pc_user'))
      if(pc_user.user_name){
        next()
      }
      else{
        next({path: '/login', query: {selected: 1}})
      }
    },
    component:() => import(/*webpackChunkName */ '@/views/scanCode/index.vue'),
    meta:{
      nav:true,
      navi:true
    }
  },
  {
    path:'/payResult',
    name:'payResult',
    beforeEnter: (to, from,next) => {
      let pc_user = JSON.parse(localStorage.getItem('pc_user'))
      if(pc_user.user_name){
        next()
      }
      else{
        next({path: '/login', query: {selected: 1}})
      }
    },
    component:() => import(/*webpackChunkName */ '@/views/paydetail/payResult.vue'),
    meta:{
      nav:true,
      navi:true
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
