import axios from "axios";
// import {useRouter} from 'vue-router'
axios.defaults.baseURL = 'https://admin.iseso.net/'

// axios.defaults.withCredentials =true

axios.defaults.headers['x-Requested-With'] = 'XMLHttpRequest'

axios.defaults.headers.post['Content-type'] = 'Application/json'

// const router = useRouter()
axios.interceptors.response.use(res=>{
    if(res.data.code ===200){
        return res.data
    }
    else if(res.data.code ==404){
        console.log('xxxx')
        localStorage.removeItem('pc_user')
    }
    else{
        return res.data
    }
    
})

export default axios
